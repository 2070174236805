import * as React from 'react'
import { Helmet } from 'react-helmet'

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" lang="it" />
        <title>Conosci il tuo cane - Luisella Casarini</title>
        <link rel="canonical" href="http://conosciiltuocane.it/" />
      </Helmet>
      <main>
        <p>WORK IN PROGRESS - 🚧</p>
      </main>
    </>
  )
}

export default IndexPage
